@media (max-width: 1424px) {
  .sidebar:not(.slim) + main .preparation .selected-topic-card {
    width: 330px;
  }
  .sidebar:not(.slim) + main .preparation .ai-topics-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .sidebar:not(.slim) + main .preparation .date-selector-card {
    padding: 16px;
  }
}

@media (max-width: 1068px) {
  .sidebar:not(.slim) + main .preparation .selected-topic-card {
    top: auto;
    position: fixed;
    height: 40vh;
    margin-left: 350px;
    width: calc(100vw - 350px);
    width: calc(100dvw - 350px);
    bottom: 0;
    left: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding-top: 16px;
  }

  .sidebar:not(.slim) + main .preparation .preparation-audience-mobile {
    display: flex;
  }

  .sidebar:not(.slim) + main .preparation .preparation-audience-before-desktop {
    display: none;
  }
  .sidebar:not(.slim) + main .preparation .preparation-audience-after-desktop {
    display: none;
  }
  
  
  .sidebar:not(.slim) + main .preparation .date-selector-action-card {
    width: calc(100vw - 350px);
    width: calc(100dvw - 350px);
    left: 0;
    margin-left: 350px;
    padding-top: 16px;
  }
  .sidebar:not(.slim) + main .preparation .topic-select-box {
    margin-bottom: 271px;
  }
}

@media (max-width: 940px) {
  .sidebar:not(.slim) + main .preparation .date-selector-card-desktop {
    display: none;
  }
  .sidebar:not(.slim) + main .preparation .date-selector-card-mobile {
    display: flex;
  }
  .sidebar:not(.slim) + main .preparation .date-selector-card {
    margin-bottom: 128px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 768px) {
  .sidebar:not(.slim) + main .preparation .selected-topic-card {
    margin-left: 0;
    width: 100vw;
    width: 100dvw;
    bottom: 0;
    left: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .sidebar:not(.slim) + main .preparation .date-selector-card {
    margin-bottom: 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sidebar:not(.slim) + main .preparation .date-selector-action-card {
    width: 100vw;
    width: 100dvw;
    left: 0;
    margin-left: 0;
  }
  .sidebar:not(.slim) + main .preparation .preparation-audience-mobile {
    display: none;
  }

  .sidebar:not(.slim) + main .preparation .preparation-audience-before-desktop {
    display: flex;
  }
  .sidebar:not(.slim) + main .preparation .preparation-audience-after-desktop {
    display: flex;
  }
  .sidebar:not(.slim) + main .preparation .date-selector-card-desktop {
    display: flex;
  }
  .sidebar:not(.slim) + main .preparation .date-selector-card-mobile {
    display: none;
  }
  .sidebar:not(.slim) + main .preparation .date-selector-card {
    margin-bottom: 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}


@media (max-width: 640px) {
  .sidebar:not(.slim) + main .preparation .date-selector-card {
    margin-bottom: 128px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .sidebar:not(.slim) + main .preparation .preparation-audience-mobile {
    display: flex;
  }

  .sidebar:not(.slim) + main .preparation .preparation-audience-before-desktop {
    display: none;
  }
  .sidebar:not(.slim) + main .preparation .preparation-audience-after-desktop {
    display: none;
  }
  .sidebar:not(.slim) + main .preparation .date-selector-card-desktop {
    display: none;
  }
  .sidebar:not(.slim) + main .preparation .date-selector-card-mobile {
    display: flex;
  }
}

#audience-popup {
  max-height: calc(100vh - 20px);
  max-height: calc(100dvh - 20px);
  overflow: auto;
}
