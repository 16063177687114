.aside * {
  color: var(--off-white-base);
}

.aside {
  width: 400px;
  height: 100vh;
  height: 100dvh;
  padding: 5rem 2rem 2rem;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.aside .avatars {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.aside .avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
}

.aside .topic {
  font-family: Figtree;
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-top: 2rem;
  color: var(--off-white-base) !important;
}

.aside .description {
  font-family: Figtree;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  color: white;
}

.aside .copyright {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-family: Figtree;
  font-size: 18px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 50%;
}

.aside .copyright a {
  display: flex;
  align-items: center;
}

.aside .copyright img {
  width: 116px;
  height: auto;
}

@media screen and (max-width: 1440px) {
  .aside {
    width: 300px;
    padding: 3rem 2rem 2rem;
  }

  .aside .avatar {
    width: 120px;
    height: 120px;
  }

  .aside .topic {
    font-size: 20px;
    line-height: 24px;
  }

  .aside .description {
    font-size: 14px;
    line-height: 19px;
  }
}

@media screen and (max-width: 1024px) {
  .aside {
    width: 250px;
    padding: 3rem 2rem 2rem;
  }

  .aside .avatar {
    width: 80px;
    height: 80px;
  }

  .aside .topic {
    font-size: 16px;
    line-height: 20px;
    margin-top: 1rem;
  }

  .aside .description {
    font-size: 12px;
    line-height: 14px;
    margin-top: 1rem;
    gap: 8px;
  }

  .aside .copyright {
    font-size: 14px;
    gap: 10px;
  }

  .aside .copyright img {
    width: 76px;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .aside {
    display: none;
  }
}
