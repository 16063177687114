.register {
  /* position: absolute; */
  width: 100%;
  /* min-height: 100%; */
  flex: 1;
  padding: 1rem 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register .main {
  width: 1200px;
  min-height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 100%;
}

.register .register-card {
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 47px;
  width: 100%;
  min-height: 100%;
}

.register .register-card .title {
  font-family: "EightiesComeback", serif;
  font-weight: 600;
  font-size: 42px;
  line-height: 30px;
  text-align: left;
  margin: 0;
}

.register .input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.register input:not(#agree-terms) {
  border-radius: 38px;
  height: 60px;
  padding: 8px 20px;
  border: none;
  font-family: "Figtree", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.register input:not(#agree-terms).error {
  border: solid 1px red;
}

.register input:not(#agree-terms):focus {
  border: rgb(190, 190, 190) solid 1px;
}

.register .submit {
  border-radius: 58px;
  padding: 30px 50px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-family: "Figtree", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 10%;
  color: #ffffff;
  border: none;
  background-color: var(--main-blue);
}

.register img.jessica {
  height: auto;
  width: 600px;
}

.register .account {
  width: 100%;
  text-align: center;
  color: var(--Gray-600, #475467);

  /* Text sm/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.register .sign-in {
  color: var(--Primary-900, #194185);
  text-decoration-line: underline;
}

.register input#agree-terms {
  width: 16px;
  height: 16px;
}

.register .terms-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--Text-Primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: "clig" off, "liga" off;

  /* Typography/Body 1 */
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
}

.register .terms-container input {
  margin: 3px 0;
}

.register .term-link {
  color: var(--Purple-CTA, #2028e6);
  font-feature-settings: "clig" off, "liga" off;

  /* Typography/Body 1 */
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  text-decoration: none;
}

.register .actions-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1280px) {
  .register .register-card {
    padding: 40px 20px;
    gap: 27px;
  }
  .register img.jessica {
    height: auto;
    width: 440px;
  }
  .register .submit {
    border-radius: 58px;
    padding: 20px 30px;
    font-size: 20px;
  }
  .register .register-card .title {
    font-size: 32px;
    line-height: 30px;
  }
  .register .main {
    gap: 2rem;
    width: 1000px;
  }
}

@media screen and (max-width: 1024px) {
  .register .register-card {
    padding: 30px 20px;
    gap: 25px;
  }
  .register .actions-container {
    gap: 10px;
  }
  .register img.jessica {
    height: auto;
    width: 340px;
  }
  .register .submit {
    border-radius: 58px;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 18px;
  }
  .register .register-card .title {
    font-size: 32px;
    line-height: 30px;
  }
  .register input:not(#agree-terms) {
    border-radius: 38px;
    height: 45px;
    padding: 8px 20px;
    font-size: 18px;
  }
  .register .main {
    gap: 1rem;
    width: 740px;
  }
}

@media screen and (max-width: 768px) {
  .register .main {
    grid-template-columns: repeat(1, 1fr);
    width: min(100%, 500px);
  }
  .register .register-card {
    width: 100%;
  }
  .register img.jessica {
    width: 400px;
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 425px) {
  .register .register-card .title {
    font-size: 28px;
    line-height: 30px;
  }
  .register input:not(#agree-terms) {
    font-size: 16px;
  }
}
