.select-component-label {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.select-component-label.focus {
  background-color: var(--mid-pink);
  color: var(--main-blue);
}