.editor .version-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  left: 0;
  top: 94px;
  position: absolute;
}

.editor .version-bubble {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: var(--blue-grey);
  font-family: Figtree;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border: 1px solid var(--light-blue);
  margin-left: -11px;
}

.editor .version-bubble.active {
  background-color: var(--main-blue);
  color: white;
  border-width: 2px;
}

.editor .version-line {
  border: 1px solid var(--light-blue);
  height: 30px;
  width: 1px;
}

@media screen and (max-width: 768px) {
  .editor .version-container {
    left: 24px;
    top: 102px;
  }
}
