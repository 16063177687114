.auth-popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #131313aa;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  min-height: max-content;
}

.auth-popup-container .auth-popup {
  position: relative;
  border-radius: 30px;
  border: 0.59px solid #5e5e5e;
  overflow: hidden;
  background: #f9f1ed;

  width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-shadow: 0px 6.157434463500977px 88.47000122070312px 0px #959da524;
}

.auth-popup-container .auth-left,
.auth-right {
  width: 50%;
  height: 100%;
  padding: 20px;
}

.auth-popup-container .auth-left {
  position: relative;
  background-color: var(--off-white-base);
}

.auth-popup-container .auth-img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-popup-container .auth-img img {
  height: 290px;
  width: auto;
}

.auth-popup-container .auth-banners {
  position: relative;
  top: -30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.auth-popup-container .auth-banners div {
  display: flex;
  padding: 10px 14px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 63px;
  backdrop-filter: blur(40px);
  color: #131313;
  font-family: Figtree;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 109.091% */
  letter-spacing: 0.38px;
}

.auth-popup-container .auth-banners div:first-child {
  transform: rotate(-3deg);
  background: var(--Green-Bright, #00bb7b);
}

.auth-popup-container .auth-banners div:nth-child(2) {
  transform: rotate(2deg);
  background: var(--Orange-Mishmish, #fe8968);
}

.auth-popup-container .auth-banners div:last-child {
  background: var(--Pink-Bright, #ff66fc);
}

.auth-popup-container .auth-right {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding-top: 40px;
  height: 100%;
}

.auth-popup-container .auth-title {
  color: #000;
  text-align: center;

  /* Display sm/Light */
  font-family: "Nunito Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 58px; /* 126.667% */
  letter-spacing: -0.3px;
}

.auth-popup-container .auth-description {
  color: #000;
  text-align: center;
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
  padding: 10px 0;
}

.auth-popup-container form {
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auth-popup-container form input,
.auth-popup-container form button {
  width: 100%;
}

.auth-popup-container .auth-field {
  width: 100%;
  position: relative;
}

.auth-popup-container form input {
  color: var(--Gray-900, var(--BLACK, #101828));

  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  border: 1px solid var(--Primary-300, #84caff);
  background: var(--Base-White, #fff);
  /* Shadow/xs focused 4px primary-100 */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #ebedff;
}

.auth-popup-container form input::placeholder {
  color: var(--Gray-400, var(--BLACK, #98a2b3));
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.auth-popup-container .auth-input-help {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.auth-popup-container form button {
  outline: none;
  padding: 10px 16px;
  border-radius: 8px;

  color: var(--Gray-100, #f2f4f7);

  border: 1px solid var(--Purple-CTA, #2028e6);
  background: var(--Purple-CTA, #2028e6);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.auth-popup-container form button.go-back {
  border: none;
  background: transparent;
  color: var(--Purple-CTA, #2028e6);
  box-shadow: none;
}

.auth-popup-container form button:disabled {
  opacity: 60%;
}

.auth-popup-container form button.wait {
  border: 1px solid var(--Gray-400, #98a2b3);
  background: var(--Gray-400, #98a2b3);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.auth-popup-container .auth-close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

@media screen and (max-width: 1440px) {
  .auth-popup-container {
    padding: 10px;
  }

  .auth-popup-container .auth-popup {
    border-radius: 30px;
    width: 950px;
  }
  .auth-popup-container .auth-img img {
    height: 275px;
  }
  .auth-popup-container .auth-banners div {
    padding: 10px 14px;
    gap: 10px;
    flex-shrink: 0;
    font-size: 18px;
    line-height: 22px; /* 109.091% */
    letter-spacing: 0.38px;
  }
  .auth-popup-container .auth-banners {
    top: -30px;
    gap: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .auth-popup-container {
    padding: 10px;
  }

  .auth-popup-container .auth-popup {
    border-radius: 30px;
    width: 640px;
  }
  .auth-popup-container .auth-popup .auth-left {
    display: none;
  }
  .auth-popup-container .auth-popup .auth-right {
    width: 100%;
    min-height: 400px;
  }
}

@media screen and (max-width: 768px) {
  .auth-popup-container {
    padding: 10px;
  }

  .auth-popup-container .auth-popup {
    border-radius: 20px;

    width: 500px;
  }
}
