.calendar .month-selector {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 40px;
  gap: 1rem;
}

@media screen and (max-width : 1440px) {
  .calendar .month-selector {
    height: 35px;
    gap: 1rem;
  }
}

@media screen and (max-width : 1024px) {
  .calendar .month-selector {
    height: 30px;
    gap: 0.5rem;
  }
}

@media screen and (max-width : 768px) {
  
}

@media screen and (max-width : 425px) {
  
}