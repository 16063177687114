.card {
  box-shadow: 0px 4px 50px 0px #7070701A;
  border-radius: 30px;
  background-color: var(--light-pink);
}

@media screen and (max-width : 768px) {
  .card {
    width: 100%;
  }
}