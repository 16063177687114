.editor .editor-actions {
  flex: 0 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #1c1f24; */
  gap: 8px;
  /* border-top: 1px solid #70707088; */
}

.editor .editor-actions form {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  /* background-color: #313742; */
  /* padding: 5px; */
  flex: 1;
  width: 100%;
}

.editor form textarea {
  border-radius: 13px;
  border: solid 1px #D0D5DD;
  color: var(--off-white-base);
  background-color: #313742;
  padding: 10px 10px;
  font-family: Figtree;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  flex: 1;
  width: 100%;
  overflow-y: auto;
  resize: none;
  background-color: white;
  color: black;
}

.editor form textarea::-webkit-scrollbar {
  width: 8px; /* Adjust scrollbar width */
  height: 12px; /* Adjust scrollbar height */
  background-color: #0000;
}

.editor form textarea::-webkit-scrollbar-thumb {
  background-color: #8d8d8d; /* Set thumb color */
  border-radius: 10px; /* Set border radius for the scrollbar thumb */
  cursor: pointer;
}

.editor form textarea::-webkit-scrollbar-button {
  display: none;
}

/* .editor .editor-actions button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid #99afff;
  background-color: #313742;
  color: #99afff;
  border-radius: 40px;
  padding: 8px 16px;
  box-shadow: 0px 0.5866666436195374px 1.1733332872390747px 0px #1018280d;
} */

/* .editor button > * {
  color: #99afff;
  fill: #99afff;
  font-family: Figtree;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
} */

.editor form input::placeholder {
  color: #9daac0;
}

.editor .editor-actions .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.editor .editor-actions .step-counter {
  font-family: Figtree;
  font-size: 14px;
  font-weight: 600;
  line-height: 11.73px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #8793a7;
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
}

.editor .editor-actions .step-counter > span {
  color: inherit;
  padding-left: 5px;
}

.editor .editor-actions .reaction img {
  width: 26px;
  height: auto;
  cursor: pointer;
  margin-right: 10px;
}

.editor .editor-actions .buttons > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
}

.editor .editor-actions .mobile {
  display: none !important;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
  .editor form .desktop {
    display: none;
  }

  .editor form .mobile {
    display: block;
  }

  .editor .editor-actions .input {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .editor .editor-actions form {
    display: flex;
    width: 100%;
  }

  .editor form textarea {
    flex: 1;
    max-width: none;
    min-width: auto;
  }

  .editor .editor-actions {
    padding: 10px;
    display: flex;
    
    gap: 10px;
  }

  .editor .editor-actions .buttons {
    width: 100%;
    justify-content: space-between;
    gap: 0.5rem;
  }
  .editor .editor-actions .buttons > div {
    width: auto;
  }

  .editor .editor-actions .mobile {
    display: flex !important;
    text-transform: uppercase;
  }
  .editor .editor-actions .desktop {
    display: none;
  }
  /* .editor .editor-actions .step-counter > span {
    display: none;
  } */
}

@media screen and (max-width: 375px) {
  .editor .editor-actions img {
    width: 26px;
    height: auto;
    cursor: pointer;
    margin-right: 0;
  }
}
