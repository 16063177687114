nav {
  width: 100%;
  height: 152px;
  display: flex;
  justify-content: space-between;
  flex: 0;
  padding: 10px 20px;
  /* background: var(--Off-white-Light, #f9f1ed00); */
}

.dashboard nav {
  padding: 10px 28px;
  height: auto;
  background: var(--Off-white-Light, #f9f1ed);
}

.posts nav, .my-calls nav {
  padding: 0;
  height: auto;
  background: var(--Off-white-Light, #f9f1ed);
}

nav .links {
  display: flex;
  align-items: center;
  gap: 40px;
}

nav .navigation {
  display: flex;
  gap: 32px;
}

nav .nav-item {
  padding: 10px 18px;
  font-family: "Figtree", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #131313;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
}

nav .nav-item:hover {
  color: #707070;
}
nav .nav-item:active {
  color: #a5a5a5;
}

nav .try-now {
  border-radius: 30px;
  padding: 10px 30px;
  background: #131313;
  box-shadow: 0px 1px 2px 0px #1018280d;
  color: #ffffff;
  transition: all 0.1s;
  text-decoration: none;
}

nav .try-now:hover {
  background: #777777;
  color: #ffffff;
}

nav .try-now:active {
  color: #bbbbbb;
}

nav .nav-container.mobile {
  justify-content: center;
}

nav .nav-container.mobile img {
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 20;
}

nav .nav-container.mobile .aside-container {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000094;
  z-index: 10;
}

nav .nav-container.mobile .aside-container.hide {
  display: none;
}

nav .nav-container.mobile .side-menu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--mid-pink);
  position: relative;
  left: -100%;
  transition: left 0.5s ease-in-out;
}

nav .nav-container.mobile .side-menu.active {
  left: 0;
}

nav .nav-container.mobile .side-item.close {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  padding: 10px 20px;
  min-height: 112px;
}

nav .nav-container.mobile .side-item.close .close-icon {
  cursor: pointer;
}

nav .nav-container.mobile .side-item:not(.close) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  cursor: pointer;
  font-family: "Figtree";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #131313;
  cursor: pointer;
}

@media screen and (max-width: 1440px) {
  nav .links {
    gap: 15px;
  }
  nav .navigation {
    display: flex;
    gap: 10px;
  }
  nav .nav-item {
    padding: 10px 1rem;
  }
  nav .nav-container {
    padding: 0 16px;
  }
}

@media screen and (max-width: 1024px) {
  nav .links {
    gap: 15px;
  }
  nav .navigation {
    display: flex;
    gap: 0;
  }
  nav .nav-item {
    padding: 10px 0.5rem;
  }
  nav .nav-container {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  nav .nav-container.mobile {
    display: flex;
  }
  nav .nav-container.desktop {
    display: none;
  }
}

@media screen and (max-width: 425px) {
}
