.counter-component {
  display: flex;
  position: relative;
  padding: 12px 12px;
  align-items: center;
  text-align: center;
  background: var(--Light-Blue, #99afff);
}

.counter-component > div {
  width: 100%;
  color: black;
}

.counter-component * {
  color: var(--Black-BG, #131313);
  text-align: center;
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}

.counter-component .billing-link {
  color: var(--CTA-BLUE, #0e24d6);
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;cursor: pointer;
}
