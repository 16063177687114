* {
  box-sizing: border-box;
}

:root {
  --main-blue: #0e24d6;
  --light-pink: #f9f1ed;
  --off-white-light: #f9f1ed;
  --mid-pink: #ece1db;
  --dark-pink: #d7ccc6;
  --light-gray: #d7ccc6;
  --light-grey: #d7ccc6;
  --black-bg: #131313;
  --gray-dark: #5f5f5f;
  --off-white-base: #ece1db;
  --gray-900: #101828;
  --gray-700: #344054;
  --light-linkedin-grey: #7c8799;
  --trans-dark-grey: #5f5f5f;
  --new-gray: #707070;
  --Primary-900: #194185;
  --CTA-BLUE: #0e24d6;
  --Light-Blue: #99afff;
  --Black-BG: #131313;
  --Blue-400: #53b1fd;
  --Off-white-Light: #f9f1ed;
  --Black-BG: #131313;
  --CTA-BLUE: #0e24d6;
  --Main-Blue: #0e24d6;
  --Green-Bright: #00bb7b;
  --Orange-Mishmish: #fe8968;
  --Pink-Bright: #FF66FC;
  --m-dark-blue: #194185;
  --light-blue: #99AFFF;
  --blue-grey: #667085;
  /* size */
  --max-width-bubble: 15px;
  --min-width-bubble: 10px;
}

@font-face {
  font-family: EightiesComeback;
  src: url("assets/fonts/Eighties Comeback OTFs/Regular/EightiesComeback-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: EightiesComeback-Co;
  src: url("assets/fonts/Eighties Comeback OTFs/Regular/EightiesComeback-ExtraCond.otf");
  font-display: swap;
}

@font-face {
  font-family: "EightiesComeback-It";
  src: url("assets/fonts/Eighties Comeback OTFs/Italic/EightiesComebackIt-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "EightiesComeback It";
  src: url("assets/fonts/Eighties Comeback OTFs/Italic/EightiesComebackIt-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("assets/fonts/HKGrotesk-Regular.otf");
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  background: var(--off-white-light);
}

.App {
  width: 100%;
  position: fixed;
  height: 100%;
  overflow: auto;
  display: flex;
}

.interact {
  transition: opacity 0.1s ease-in;
}

.interact:hover:not(:active) {
  opacity: 80%;
}

main {
  flex: 1;
  flex-shrink: 0;
  flex-grow: 1;
  position: relative;
  transition: padding-left 0.3s ease;
  display: flex;
  flex-direction: column;
  width: 100vw;
  width: 100dvw;
}

main::after {
  content: "";
  overflow: auto;
  clear: both;
}

.body {
  display: flex;
  /* flex-direction: column; */
  min-height: 100%;
  width: 100%;
}

.disabled {
  cursor: not-allowed !important;
}

.link {
  cursor: pointer !important;
}

.bg-blue {
  background-color: var(--main-blue);
}

input {
  outline: none;
}

.avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}


.scroll-dark::-webkit-scrollbar {
  width: 8px; /* Adjust scrollbar width */
  height: 8px; /* Adjust scrollbar height */
  background-color: #0000;
}

.scroll-dark::-webkit-scrollbar-thumb {
  background-color: #afafaf; /* Set thumb color */
  border-radius: 10px; /* Set border radius for the scrollbar thumb */
  cursor: pointer;
}

.scroll-hidden::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0); /* Initially transparent */
  transition: background-color 5s linear;
}

.scroll-hidden:hover::-webkit-scrollbar-thumb {
  background-color: #afafaf; /* Visible on hover */
}


.scroll-dark::-webkit-scrollbar-button {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;             /* Chrome, Safari and Opera */
}

.full-screen {
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
}

.screen-height {
  height: 100vh;
  height: 100dvh;
}

.max-screen-height {
  max-height: 100vh;
  max-height: 100dvh;
}

.min-full-screen {
  width: 100vw;
  width: 100dvw;
  min-height: 100vh;
  min-height: 100dvh;
}

.width-animation {
  transition: width 0.3s ease;
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Standard syntax */
  -webkit-user-drag: none;
}
