

.post-buttons {
  display: flex;
  justify-content: space-between;
  flex: 0 0;
}

.post-review {
  border-radius: 45px;
  background-color: var(--main-blue);
  box-shadow: 0px 0.5866666436195374px 1.1733332872390747px 0px #1018280D;
  padding: 5px 14px;
  font-family: Figtree;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-align: left;
  color: white;
  cursor: pointer;
}

.post-personalized {
  border-radius: 45px;
  background-color: var(--main-blue);
  box-shadow: 0px 0.5866666436195374px 1.1733332872390747px 0px #b9ae0b0d;
  padding: 5px 14px;
  font-family: Figtree;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-align: left;
  color: white;
  cursor: pointer;
}

.post-save>img {
  height: 13px;
}

.post-save {
  font-family: Figtree;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-align: left;
  color: var(--main-blue);
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
}

.post-save span {
  color: var(--main-blue);
}

@media screen and (max-width : 1440px) {
  .post-title {
    font-size: 18px;
    line-height: 19px;
  }
  
}

@media screen and (max-width : 1024px) {
  .post-title {
    font-size: 18px;
    line-height: 19px;
  }
}