.dashboard section .scrollbar-container > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  padding: 0 3rem;
}

.dashboard .simplebar-content {
  padding: 0 28px !important;
}

.height-transition {
  transition: all 0.5s linear; /* Adjust duration and timing function as needed */
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1024px) {
  .dashboard .simplebar-content {
    padding: 0 20px !important;
  }
}

@media screen and (max-width: 768px) {
  .dashboard .dashboard-contents-scroll-container {
    width: calc(100vw - 8px);
    width: calc(100dvw - 8px);
    position: static;
  }
}
