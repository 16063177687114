.post-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--off-white-light);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  left: 0;
  top: 0;
  z-index: 2;
}

.post-loading .loading-popup {
  border-radius: 30px;
  border: 0.59px solid #5e5e5e;
  overflow: hidden;
  background-color: var(--off-white-base);
  width: 900px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 6.157434463500977px 88.47000122070312px 0px #959da524;
  gap: 20px;
}

.post-loading .loading-popup * {
  color: var(--black-bg);
}

.popup-title {
  font-family: "EightiesComeback-It";
  font-size: 60px;
  font-weight: 400;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: center;
}

.popup-head {
  font-family: Figtree;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  opacity: 70%;
  width: 50%;
}

.popup-description {
  font-family: Figtree;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  width: 70%;
}

.popup-card {
  background-color: var(--off-white-light);
  border-radius: 30px;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.popup-content {
  font-family: Figtree;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  width: 90%;
}

.popup-content span {
  font-family: "EightiesComeback-It";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.popup-button {
  background-color: var(--main-blue);
  border-radius: 50px;
  color: #fff !important;
  padding: 20px 24px;
  margin-top: 10px;
  cursor: pointer;
  font-family: Figtree;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-align: left;
  transition: opacity 0.1s ease-in;
  text-decoration: none;
}

.popup-button:hover:not(:active) {
  opacity: 80%;
}

.popup-helper {
  font-family: Figtree;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.popup-animation {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-animation  > div > div {
  height: 300px;
}

@media screen and (max-width: 1280px) {
  .post-loading {
    padding: 10px;
  }
  
  .post-loading .loading-popup {
    border-radius: 30px;
    width: 800px;
    padding: 30px;
    gap: 15px;
  }
  
  .popup-title {
    font-size: 50px;
    line-height: 55px;
  }
  
  .popup-head {
    font-size: 18px;
    line-height: 22px;
    width: 60%;
  }
  
  .popup-description {
    font-size: 20px;
    line-height: 24px;
    width: 70%;
  }
  
  .popup-card {
    border-radius: 30px;
    padding: 30px;
    gap: 10px;
  }
  
  .popup-content {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }
  
  .popup-content span {
    font-size: 16px;
    line-height: 20px;
  }
  
  .popup-button {
    border-radius: 50px;
    padding: 16px 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }

  .popup-helper {
    font-size: 14px;
    line-height: 14px;
  }

  .popup-animation {
    height: 80px;
  }
  
  .popup-animation  > div > div {
    height: 250px;
  }
}

@media screen and (max-width: 1024px) {
  .post-loading {
    padding: 10px;
  }
  
  .post-loading .loading-popup {
    border-radius: 30px;
    width: 640px;
    padding: 30px;
    gap: 15px;
  }
  
  .popup-title {
    font-size: 40px;
    line-height: 45px;
  }
  
  .popup-head {
    font-size: 16px;
    line-height: 20px;
    width: 60%;
  }
  
  .popup-description {
    font-size: 18px;
    line-height: 22px;
    width: 80%;
  }
  
  .popup-card {
    border-radius: 30px;
    padding: 20px;
    gap: 10px;
  }
  
  .popup-content {
    font-size: 14px;
    line-height: 18px;
    width: 100%;
  }
  
  .popup-content span {
    font-size: 14px;
    line-height: 18px;
  }
  
  .popup-button {
    border-radius: 50px;
    padding: 14px 18px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }

  .popup-helper {
    font-size: 12px;
    line-height: 12px;
  }
  .popup-animation {
    height: 60px;
  }
  
  .popup-animation  > div > div {
    height: 200px;
  }
}

@media screen and (max-width: 768px) {
  .post-loading {
    padding: 10px;
  }
  
  .post-loading .loading-popup {
    border-radius: 20px;
    width: 500px;
    padding: 10px;
    gap: 8px;
  }
  
  .popup-title {
    font-size: 30px;
    line-height: 35px;
  }
  
  .popup-head {
    font-size: 14px;
    line-height: 14px;
    width: 100%;
  }
  
  .popup-description {
    font-size: 14px;
    line-height: 14px;
    width: 100%;
  }
  
  .popup-card {
    border-radius: 20px;
    padding: 10px;
    gap: 5px;
  }
  
  .popup-content {
    font-size: 14px;
    line-height: 14px;
    width: 100%;
  }
  
  .popup-content span {
    font-size: 14px;
    line-height: 18px;
  }
  
  .popup-button {
    border-radius: 50px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }

  .popup-helper {
    font-size: 12px;
    line-height: 12px;
  }
  .popup-animation {
    height: 80px;
  }
  
  .popup-animation  > div > div {
    height: 200px;
  }
}
