.editor {
  min-height: 100vh;
  min-height: 100dvh;
  background-color: var(--off-white-light);
  display: flex;
}

.editor .simplebar-content {
  padding: 0 !important;
}

.editor section {
  flex: 1;
  height: 100vh;
  height: 100dvh;
  max-height: 100vh;
  max-height: 100dvh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background-image: url("assets/images/meeting/meeting-back.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.editor section .close {
  min-height: 120px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0;
}

.editor section .close img {
  width: 34px;
  height: 34px;
  cursor: pointer;
}

.editor .editor-edit {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  /* background-color: #1C1F24; */
  /* overflow: hidden; */
}

.editor .editor-body {
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  /* padding: 20px; */
}

.editor .editor-area {
  width: 100%;
  text-align: justify;
  text-wrap: pretty;
  margin: 0;
  font-family: "Figtree", "Apple Color Emoji", "Segoe UI Emoji", "Noto Color Emoji", "Android Emoji", "EmojiSymbols";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  /* background-color: #1c1f2400; */
  background-color: transparent;
  resize: none;
  /* height: auto; */
  /* padding: 20px; */
  overflow: auto;
  overflow-x: hidden;
}

.editor textarea:focus {
  outline: none;
}

.editor section aside {
  padding: 0;
  align-items: normal;
}

@media screen and (max-width: 1440px) {
  .editor .section {
    padding: 0 2rem;
  }

  .editor section .close {
    min-height: 100px;
  }

  .editor section .close img {
    width: 26px;
    height: 26px;
  }

  .editor section .title {
    font-size: 28px;
    line-height: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .editor section .close {
    min-height: 80px;
  }

  .editor section .close img {
    width: 20px;
    height: 20px;
  }

  .editor section {
    padding: 0 1.5rem;
  }

  .editor section .description {
    font-size: 14px;
    line-height: 18px;
    padding: 10px 0;
  }
}

@media screen and (max-width: 768px) {
  .editor {
    min-height: none;
  }

  .editor section {
    padding: 0;
    background-position: center right;
  }

  .editor .section-main {
    min-height: none;
    max-height: none;
    padding: 0 1.5rem;
  }

  .editor .editor-edit {
    min-height: 400px;
  }
}

@media screen and (max-width: 375px) {
}
