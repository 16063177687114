.sidebar-box {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #131313;
  color: white;
  border-radius: 0px 30px 30px 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 350px;
  max-width: 100vw;
  max-width: 100dvw;
  height: 100vh;
  height: 100dvh;
  border-radius: 0px 30px 30px 0px;
  overflow: hidden;
  z-index: 50;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.sidebar.slim {
  width: 75px;
}

.sidebar button {
  cursor: pointer;
}

.sidebar-content {
  padding: 10px;
}

.sidebar-content p {
  padding: 10px 0;
}

.full-logo-wrapper {
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease;
  padding: 8px 8px 8px 12px;
  background-color: #131313;
}

.sidebar.slim + main {
  padding-left: 75px;
}
.sidebar + main {
  padding-left: 350px;
}
.sidebar + main .aside {
  display: none;
}

.icon-container {
  position: sticky;
  display: flex;
  height: 64px;
  padding-top: 20px;
  padding-bottom: 14px;
  cursor: pointer;
  background-color: #131313;
  z-index: 9;
}

.icon-container .slim-logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 32px;
  height: 32px;
}

.icon-container .full-logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 18px;
  padding-right: auto;
  height: 64px;
  width: 120px;
}

.slim-logo {
  transition: opacity 0.3s ease;
}

.full-logo {
  position: absolute;
  transition: opacity 0.3s ease, width 0.2s ease;
  /* Added transition */
}

.burger-icon {
  width: 48px;
  /* Adjust size as needed */
  height: 48px;
  /* Space between burger icon and full logo */
}

/* .sidebar-menu-list { */
/* padding-top: 15px; */
/* } */

.sidebar-menu-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #131313;
}

.sidebar-menu-item-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-inline: 14px;
  background-color: #131313;
  position: sticky;
  top: 64px;
  z-index: 1;
}

.sidebar-menu-item-header.slim {
  justify-content: normal;
  width: initial;
  padding-left: auto;
  padding-right: auto;
}

.sidebar-menu-item-title {
  display: flex;
}

.sidebar-menu-item-title.profile {
  align-items: flex-end;
  padding-right: 8px;
}

.sidebar-menu-item-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.sidebar-menu-item-text {
  padding-left: 32px;
}

.sidebar-menu-item-child-text {
  color: var(--White, #fff);
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/Body 2 */
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  /* 20.02px */
  letter-spacing: 0.15px;
}

.sidebar-menu-item-expander {
  padding-right: 0;
  cursor: pointer;
}

.sidebar-menu-item-child {
  padding: 10px 18px 10px 53px;
  min-height: 60px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-content: center;
  cursor: pointer;
}


.sidebar-menu-active-child {
  background: rgba(14, 36, 214, 0.4);
}

.sidebar-menu-item-child-date {
  color: var(--Grey-Grey-500, #9E9E9E);
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/Body 2 */
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 20.02px */
  letter-spacing: 0.15px;
}

.sidebar-menu-schedule-call {
  border-radius: 45px;
  background-color: var(--main-blue);
  box-shadow: 0px 0.5866666436195374px 1.1733332872390747px 0px #1018280d;
  padding: 8px 22px;
  gap: 8px;
  flex: 1;
  font-weight: 600;
  text-align: center;
  color: white;
  cursor: pointer;
}

.sidebar-menu-schedule-call-text {
  color: var(--Primary-Contrast, #fff);
  font-feature-settings: "clig" off, "liga" off;
  /* Components/Button Large */
  font-family: Figtree;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 1.45px;
}

.sidebar-menu-profile-user {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  border: 1px solid #000;
  z-index: 1;
}

.sidebar-menu-profile-jessica {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  flex-shrink: 0;
  margin-left: -10px;
  background: rgb(196, 199, 0) 50% / cover no-repeat;
}

.sidebar-menu-item-child-link:hover .more-btn {
  display: block;
}

@media screen and (max-width: 768px) {
  main {
    padding-left: 0 !important;
  }
  .sidebar.slim {
    width: 0;
  }
  .sidebar {
    width: 400px;
  }
  .sidebar-menu-item-child-link .more-btn {
    display: block;
  }
}
