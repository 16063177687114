.calendar .date-selector {
  width: 100%;
}

.calendar .calendar-days {
  width: 100%;
  display: flex;
}

.calendar .calendar-days > div {
  flex: 1;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.calendar .calendar-week {
  width: 100%;
  display: flex;
}

.calendar .date {
  flex: 1;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 40px;
  cursor: pointer;
  padding: 10px 0;
}

.calendar .date.notThisMonth {
  color: #6c6a72;
}

.calendar .date.today {
  background-color: var(--light-gray);
}

.calendar .date.selected {
  color: white;
  background-color: var(--main-blue);
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1024px) {
  .calendar .date {
    padding: 10px 0;
  }
}

@media screen and (max-width: 1068px) {
  .sidebar:not(.slim) + main .calendar .date {
    padding: 10px 0px;
  }
}

@media screen and (max-width: 768px) {
  .calendar .date {
    padding: 10px 0px;
  }
}
