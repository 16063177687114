.schedule {
  /* position: absolute; */
  width: 100%;
  /* min-height: 100%; */
  padding: 1rem 1rem 2rem;
  display: flex;
  align-items: center;
  flex: 1;
}

.schedule .done {
  font-family: "EightiesComeback-It";
  font-size: 60px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--black-bg);
  flex-wrap: wrap-reverse;
}

.schedule .main {
  width: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.schedule img {
  height: 100%;
}

.schedule .right-box {
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 580px;
}

.schedule .interviewer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 2rem;
}

.schedule .interviewer > div {
  flex: 1;
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.schedule .interviewer img {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 0;
}

.schedule .name {
  font-family: "EightiesComeback", serif;
  font-size: 27px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.schedule .title {
  font-family: "EightiesComeback-It", serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
}

.schedule .scheduled-card {
  width: 100%;
  padding: 38px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.schedule .time {
  width: 100%;
  border: none;
  font-family: Figtree;
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
}

.schedule .duration-description {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.schedule .duration {
  font-family: Figtree;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.schedule .description {
  font-family: Figtree;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.schedule .timezone {
  font-family: Figtree;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.schedule .button-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 22px;
  flex-wrap: wrap-reverse;
}

.schedule button {
  border-radius: 40px;
  padding: 20px 35px;
  border: none;
  font-family: Figtree;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: center;
  cursor: pointer;
  transform: scale(1);
  transition: transform 300ms ease-out;
  width: 360px;
  max-width: 100%;
  background-color: var(--main-blue);
  color: white;
}

.schedule .back {
  background-color: var(--dark-pink);
  flex: 1;
  color: #404752;
}

.schedule .continue {
  background: none;
  color: var(--main-blue);
  border: solid 1px var(--main-blue);
}

.schedule .right-box button:active {
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  transform: scale(1.05);
}

.schedule .choose {
  color: var(--main-blue);
  background: none;
  border: var(--main-blue) solid 1px;
  width: 100%;
}

.schedule .choose.mobile {
  display: none;
}

@media screen and (max-width: 1440px) {
  .schedule {
    padding: 1rem 3rem 2rem;
  }

  .schedule .done {
    font-size: 48px;
    line-height: 48px;
  }

  .schedule .title {
    font-size: 32px;
    line-height: 32px;
  }

  .schedule img {
    height: 488px;
  }

  .schedule .main {
    gap: 2rem;
  }

  .schedule .interviewer img {
    width: auto;
    height: 100%;
  }

  .schedule .interviewer {
    gap: 1rem;
  }

  .schedule .right-box button {
    flex: 1;
    padding: 20px 25px;
  }

  .schedule .right-box {
    /* gap: 30px; */
    max-width: 500px;
  }
}

@media screen and (max-width: 1024px) {
  .schedule {
    padding: 1rem 1.5rem 2rem;
  }

  .schedule .done {
    font-size: 48px;
    line-height: 48px;
  }

  .schedule .title {
    font-size: 32px;
    line-height: 32px;
  }

  .schedule .selector-card {
    padding: 20px 20px;
    gap: 10px;
    width: 100%;
    min-height: 388px;
  }

  .schedule img {
    height: 388px;
  }

  .schedule input {
    border-radius: 38px;
    height: 45px;
    padding: 8px 20px;
    font-size: 18px;
  }

  .schedule .main {
    gap: 1rem;
  }

  .schedule .right-box {
    gap: 12px;
    max-width: 450px;
  }

  .schedule .interviewer img {
    width: 120px;
    height: auto;
  }

  .schedule .name {
    font-size: 26px;
    line-height: 30px;
  }

  .schedule .schedule-card {
    padding: 19px;
  }

  .schedule .time {
    font-size: 18px;
    line-height: 35px;
  }

  .schedule .duration {
    font-size: 14px;
  }

  .schedule .description {
    font-size: 12px;
  }

  .schedule .right-box button {
    font-size: 16px;
    flex: 1;
    padding: 10px 10px;
  }

  .schedule .button-box {
    gap: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .schedule .done {
    font-size: 40px;
    line-height: 42px;
  }
  .schedule .name {
    font-size: 20px;
    line-height: 28px;
  }

  .schedule .title {
    font-size: 27px;
    line-height: 26px;
  }
  .schedule .interviewer img {
    width: 100px;
    height: auto;
  }
  .schedule .back {
    text-wrap: nowrap;
  }

  .schedule .choose.mobile {
    display: block;
  }

  .schedule .choose.desktop {
    display: none;
  }
  .schedule .right-box {
    gap: 12px;
    max-width: 400px;
  }
}

@media screen and (max-width: 425px) {
  .schedule .done {
    font-size: 28px;
    line-height: 40px;
  }
  .schedule .name {
    font-size: 18px;
    line-height: 20px;
  }

  .schedule .title {
    font-size: 20px;
    line-height: 22px;
  }

  .schedule .interviewer img {
    width: 80px;
    height: auto;
  }

  .schedule .interviewer {
    gap: 10px;
  }

  .schedule .button-box {
    flex-direction: column-reverse;
  }

  .schedule button {
    padding: 10px 20px;
    font-size: 16px;
    line-height: 20px;
  }
}
