.editor .editor-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

.editor .editor-buttons > div {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
}

.editor .editor-buttons .button {
  display: flex;
  flex-direction: row;
  gap: 0.35rem;
  align-items: center;
  border-radius: 45px;
  cursor: pointer;
  font-family: Figtree;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-align: left;
  text-wrap: nowrap;
  padding: 10px 0;
}

.editor .editor-buttons .button.later {
  border: 1px solid;
  box-shadow: 0px 0.5866666436195374px 1.1733332872390747px 0px #1018280d;
  border: 2px solid black;
}

.editor .editor-buttons .button.review-final {
  box-shadow: 0px 0.5866666436195374px 1.1733332872390747px 0px #1018280d;
  background-color: var(--main-blue);
  padding: 16px 30px;
  color: white;
}

.editor .right-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.editor .right-buttons > div {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Figtree;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-align: left;
}

.editor .editor-buttons .mobile {
  display: none;
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1024px) {
  .editor .editor-buttons {
    gap: 0.5rem;
  }

  .editor .buttons {
    gap: 0.5rem;
  }

  .editor .editor-buttons .button {
    gap: 0.35rem;
    padding: 3px 0;
    border-radius: 45px;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    justify-content: center;
  }
  .editor .editor-buttons .button.review-final {
    padding: 3px 20px;
  }
  .editor .editor-buttons .feedback {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  /* .editor .editor-buttons {
    flex-direction: column;
  } */

  .editor .editor-buttons > div {
    width: 100%;
  }

  .editor .editor-buttons .buttons {
    justify-content: space-between;
  }

  .editor .editor-buttons .button.review-final {
    width: 100%;
  }

  .editor .desktop {
    display: none !important;
  }

  .editor .editor-buttons .mobile {
    display: flex;
  }

  .editor .editor-buttons .desktop {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .editor .editor-buttons .button {
    gap: 0.35rem;
    padding: 2px 10px;
    border-radius: 45px;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    justify-content: center;
  }
}
