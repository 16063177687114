.personalized-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #131313;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  min-height: max-content;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.personalized-container .personalized {
  position: relative;
  border-radius: 30px;
  border: 0.59px solid #5e5e5e;
  overflow: hidden;
  background: #f9f1ed;

  width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-shadow: 0px 6.157434463500977px 88.47000122070312px 0px #959da524;
}

.personalized-container .upgrade-left,
.upgrade-right {
  width: 50%;
  height: 100%;
  padding: 20px;
}

.personalized-container .upgrade-left {
  position: relative;
  background-color: var(--off-white-base);
  padding: 70px 60px;
}

.personalized-container .upgrade-banners {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 45px;
}

.personalized-container .upgrade-banners .upgrade-banner {
  display: flex;
  align-items: flex-end;
  gap: 24.741px;
}

.personalized-container .upgrade-right {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding: 60px 40px 40px;
  height: 100%;
}

.personalized-container .upgrade-banner-title {
  color: #000;
  font-family: Figtree;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 39px */
  width: 100%;
}

.personalized-container .upgrade-banner-description {
  color: #000;
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.personalized-container .upgrade-title {
  color: #000;
  font-size: 46.98px;
  font-weight: 400;
  line-height: 103%; /* 48.389px */
}

.personalized-container .upgrade-left .upgrade-title {
  margin-bottom: 70px;
}

.personalized-container .upgrade-title span {
  color: #000;
  font-family: "EightiesComeback It";
  font-size: inherit;
  font-style: normal;
  font-weight: 500;
  line-height: 103%;
}

.personalized-container .upgrade-description {
  color: #000;
  text-align: center;
  font-family: Figtree;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
}

.personalized-container .upgrade-right .upgrade-submit {
  display: flex;
  width: 344.128px;
  padding: 22.492px 37.487px;
  justify-content: center;
  align-items: center;
  gap: 8.997px;
  border-radius: 40px;
  background: var(--Main-Blue, #0e24d6);
  border: none;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Base-White, #fff);
  font-family: Figtree;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.994px; /* 81.789% */
  text-transform: capitalize;
  margin: 40px 0;
  cursor: pointer;
  max-width: 100%;
}

.personalized-container .upgrade-close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.personalized-container .upgrade-avatars img {
  position: relative;
  margin: 0 -5px;
  display: inline;
}

.personalized-container .upgrade-used {
  color: #000;
  font-family: Figtree;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 23.4px */
}

.personalized-container .upgrade-bubbles {
  position: relative;
  height: 20px;
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .personalized-container {
    padding: 10px;
  }
  .personalized-container .upgrade-title {
    font-size: 36px;
  }

  .personalized-container .upgrade-description {
    font-size: 18px;
  }

  .personalized-container .upgrade-right .upgrade-submit {
    font-size: 20px;
    margin: 20px 0;
    padding: 16px 0;
  }

  .personalized-container .personalized {
    border-radius: 30px;
    width: 950px;
  }
  .personalized-container .upgrade-banners {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }

  .personalized-container .upgrade-banners .upgrade-banner {
    gap: 15px;
  }

  .personalized-container .upgrade-banner-title {
    font-size: 20px;
  }
  
  .personalized-container .upgrade-banner-description {
    font-size: 14px;
  }

  .personalized-container .upgrade-banners .upgrade-banner img {
    height: auto;
    width: 70px;
  }

  .personalized-container .upgrade-right {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    padding: 60px 40px 40px;
    height: 100%;
  }
  .personalized-container .upgrade-left {
    padding: 70px 30px;
  }
}

@media screen and (max-width: 1024px) {
  .personalized-container {
    padding: 10px;
  }

  .personalized-container .personalized {
    border-radius: 30px;
    width: 640px;
  }
  .personalized-container .personalized .upgrade-left {
    display: none;
  }
  .personalized-container .personalized .upgrade-right {
    width: 100%;
    min-height: 400px;
  }
}

@media screen and (max-width: 768px) {
  .personalized-container {
    padding: 10px;
    background-position: right center;
  }

  .personalized-container .personalized {
    border-radius: 20px;
    width: 500px;
  }
  .personalized-container .upgrade-right {
    padding: 40px 10px;
  }
}
